export const apiConfig = {
  getThresholds: 'thresholds',
  getThresholdDefaults: 'thresholds',
  getPatientThresholds: 'cpm-data/patient-data/threshold',
  updatePatientThresholds: 'cpm-data/patient-data/threshold',
  getPatientComments: 'cpm-data/patient-data/patient-comment',
  insertPatientComment: 'cpm-data/patient-data/patient-comment',
  getAvailableReadingsByPatientId: 'cpm-data/patient-data/available-readings',
  updateDeviceReadingParametersOnPacketId: 'cpm-data/patient-data/available-readings',
  clearThresholdBreachCount: 'cpm-data/patient-data/threshold-breach',
  getReadingDataByPacketId: 'cpm-data/patient-data/ecg-sound',
  getDerivedDataByPatientId: 'cpm-data/patient-data/clinical-parameter',
  insertIntoAuditLogs: 'cpm-data/audit-log',
  getHelpCenterPageTextList: 'cpm-data/help-center',
  getEcgDataByPatientId: 'cpm-data/patient-data/ecg',
  getPatientDetailByInstitutionId: 'cpm-data/patient-data/patient-detail',
  getPatientTrendsByPatientId: 'cpm-data/patient-data/trends',
  getPatientTrendsMeanByPatientId: 'cpm-data/patient-data/trends/mean',
  GetPatientsByInstitutionId: 'institutions/',
  GetMaximumDetailsOfUserByEmailId: 'cpm-data/user-data/max-details',
  searchPatient: 'cpm-data/patient-data/patient-search',
  isBaselineReadingExistsByPatientId: 'cpm-data/patient-data/base-line-exists',
  generateExternalToken: 'tokens/filter',
  getPatientSummary: 'summaries',
  getTermsAndCondition: 'cpm-data/client/tnc',
  acceptTermsAndCondition: 'user-actions/tnc',
  getPatientHistoryImplants: 'implants',
  getPatientHistoryProcedures: 'procedures',
  getPatientHistoryAdmissions: 'admissions',
  getPatientMedicationList: 'medications',
  getPatientComorbiditiesList: 'comorbidities',
  getPatientActions: 'actions',
  addPatientAction: 'actions',
  getPAtientActionComments: 'comments',
  addActionComment: 'comments',
  getPatientVitalsByPatientId: 'vitals',
  updatePatientVitals: 'vitals',
  savePatientVitals: 'vitals',
  institutions: 'institutions',
  patients: 'patients',
  providers: 'providers',
  parameterBreaches: 'cpm-data/patient-data/list-breach-param',
  getBreachAuditLogs: 'cpm-data/patient-data/threshold-breach-log'
};
