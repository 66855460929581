import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmLeaveComponent } from '../components/shared/confirm-leave/confirm-leave.component';

export interface ComponentCanDeactivate {
  canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable()
export class DeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(public dialog: MatDialog) {}
  canDeactivate(
    component: ComponentCanDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (
      !component.canDeactivate() ||
      !state.url.match(/patient/g) ||
      !route.paramMap.get('id')
    ) {
      const dialogRef = this.dialog.open(ConfirmLeaveComponent, {
        width: '400px',
        disableClose: true
      });

      return dialogRef.componentInstance.onConfirm.pipe(
        map((isConfirmed: boolean) => {
          return isConfirmed;
        })
      );
    }
    return true;
  }
}
