<header class="header mb-4">
  <div class="container-fluid">
    <div class="main-nav row">
      <div class="col-auto">
        <img alt="Logo" class="logo" src="/assets/cpm-logo-whitemdpi.png">
      </div>
    </div>
  </div>
</header>
<div class="container">
  <div [class]="[loadingStatus]" class="row mb-5 justify-content-center align-items-center tnc">
    <ng-container *ngIf="loadingStatus === LoaderStatus.Loaded">
      <div *ngIf="termsAndCondition" class="col-md-6">
        <h3>
          <span *ngIf="termsAndCondition.isTncUpdated === 'Y'"
                class="badge badge-warning mr-1"
                style="color:#fff;" translate>
            NEW_TEXT
          </span>
          <span translate>TERMS_TITLE_TEXT</span>
          <span *ngIf="termsAndCondition.isTncUpdated === 'Y'" class="text-warning ml-1 start-size">*</span>
        </h3>
        <h5 *ngIf="termsAndCondition.isTncUpdated === 'Y'" class="text-warning ml-3 mt-2"><span
          class="start-size">*</span>
          <span>TNC_UPDATE_TEXT</span>
        </h5>
        <p [innerHTML]="termsAndCondition.tncText" class="mt-4"></p>
        <p>
          <span translate>TERMS_ERR_PRIVACY_TEXT</span>
          <a href="{{privacyAndCookiesLink}}"
            target="_blank" translate>TERMS_ERR_PRIVACY_COKIES_TEXT</a>
        </p>
        <hr class="mt-15">
        <div class="row justify-content-between mb-3">
          <div class="col-auto">
            <button (click)="onDoNotAcceptTermsAndCondition()" mat-raised-button >
              <span translate>NOT_ACCEPT_TEXT</span>
            </button>
          </div>
          <div class="col-auto">
            <button (click)="onAcceptTermsAndCondition()" color="primary" mat-flat-button >
              <span translate>ACCEPT_TEXT</span>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
