import { environment as Environment } from "../../environments/environment";

const { baseURLExternal } = Environment.appConfig;

export const ExternalLinks = {
  safetySecurity: `${baseURLExternal}safety-security`,
  rulesPolicies: `${baseURLExternal}rules-policies`,
  termsService: `${baseURLExternal}terms-services`,
  privacyCookies: `${baseURLExternal}policies-cookies`,
  californiaResidentPolicies: `${baseURLExternal}california-residents-policy`,
  webInstructionGuide: `${baseURLExternal}web-instruction-guide`,
  clinicalInstructionManual: `${baseURLExternal}clinical-instruction-manual`,
  patientInstructionManual: `${baseURLExternal}patient-instruction-manual`,
  exportEMRLink: `${baseURLExternal}emr-status`,
  contactUsPrivacyPolicy: `${baseURLExternal}privacy-policy`,
  patientQuickStart: `${baseURLExternal}patient-quick-start`,
  clinicianQuickStart: `${baseURLExternal}clinician-quick-start`,
  patientQuickStartVideo: `${baseURLExternal}patient-quick-start-video`,
  clinicianQuickStartVideo: `${baseURLExternal}clinician-quick-start-video`,
};
