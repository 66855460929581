import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'lastWord' })
export class LastWordPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const words = value.split(' ');
    return words[words.length - 1];
  }
}
