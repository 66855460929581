<div class="notifications">
  <div class="title-wrapper">
    <h2 mat-dialog-title translate>CPM_CONFIRM_LEAVE_TITLE
    </h2>
  </div>
  <mat-dialog-content>
    <div class="row justify-content-end">
      <div class="col-12">
        <p translate>CPM_CONFIRM_LEAVE_CONTENT</p>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center" class="btn-block">
    <button (click)="onAccept()" class="btn save-btn" color="primary"
      mat-button>{{'CPM_CONFIRM_LEAVE_BTN' | translate}}</button>
    <button class="btn cancel-btn" (click)="onCancel()" mat-button mat-dialog-close>{{'CPM_CONFIRM_LEAVE_CANCEL_BTN' |
      translate}}</button>
  </mat-dialog-actions>
</div>