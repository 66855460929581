<!-- <div>
  <mat-icon class="material-icons mr-2">
    {{data.status === 'success' ? 'check' : 'error'}}
  </mat-icon> <span class="toast-msg">{{data.message}}</span>
  <button *ngIf="data.hasAction" mat-icon-button (click)="snackBarRef.dismiss()">
    <mat-icon>close</mat-icon>
  </button>
</div> -->


<div [ngClass]="{'justify-content-between':data.hasAction}" class="with-action d-flex">
  <mat-icon class="material-icons mr-3">
    {{data.status === 'success' ? 'check' : data.hasAction ? 'warning' : 'error' }}
  </mat-icon>
  <span class="toast-msg">{{data.message}}</span>
  <mat-icon (click)="snackBarRef.dismiss()" *ngIf="data.hasAction" class="ml-3 cur-pointer close-icon">close</mat-icon>
</div>
