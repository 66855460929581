import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dateTz' })
export class DateTzPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      return moment.utc(value).local();
    }
  }
}
