import { IConfig } from '../interfaces/config';

export const config = Object.freeze({
  autoLogOffTimeInSeconds: 1140,
  superAdmin: 'super_admin',
  institutionAdmin: 'institution_admin',
  mobileClinician: 'mobile_clinician',
  webClinician: 'web_clinician',
  SA: 'SA',
  IA: 'IA',
  CL: 'CL',
  clientType: 'WEB',
  loginFailAction: 'Failure',
  loginSuccessAction: 'Success',
  portalAccessMobile: 'MOBILE',
  noRole: 'NA',
  deviceStatusAssigned: 'A',
  deviceStatusUnassigned: 'U',
  deviceStatusObsolete: 'O',
  deviceStatusOutOfOrder: 'OO',
  inactiveStatus: 'I',
  activeStatus: 'A',
  instPlcyOldrThnDropOneYearInMonth: '12',
  instPlcyOldrThnDropTwoYearInMonth: '24',
  instPlcyOldrThnDropThreeYearInMonth: '36',
  instPlcyOldrThnDropFourYearInMonth: '48',
  instPlcyAutoArchFreqOnceAWeek: 'ONCE_IN_A_WEEK',
  instPlcyAutoArchFreqOnceAMonth: 'ONCE_IN_A_MONTH',
  instPlcyAutoLogOffTimeFifteenMinutes: '15',
  instPlcyAutoLogOffTimeThirtyMinutes: '30',
  instPlcyAutoLogOffTimeFortyFiveMinutes: '45',
  instPlcyAutoLogOffTimeOneHourInMinutes: '60',
  instPlcyAuditLogDurationToStoreInThreeMonth: '3',
  instPlcyAuditLogDurationToStoreInSixMonth: '6',
  instPlcyAuditLogDurationToStoreInNineMonth: '9',
  instPlcyAuditLogDurationToStoreInTwelveMonth: '12',
  instPlcyCompSevnDays: '7',
  instPlcyCompFourteenDays: '14',
  instPlcyCompThirtyDays: '30',
  actionOpenStatus: 'OPEN',
  actionCloseStatus: 'CLOSED',
  deviceTypWearable: 'Device',
  deviceTypBaseStation: 'Base Station',
  deviceTypMobile: 'Mobile App',
  snackBarDurationInSeconds: 5,
  matDialogEdit: 'edit',
  matDialogCreate: 'create',
  confirmYes: 'Yes',
  confirmNo: 'No',
  patientIdFirstName: 'James',
  patientIdLastName: 'Smith',
  patientIdDOB: '01-01-1999', // MM-DD-YYYY
  patientIdSocialSecurityNumber: '123456789',
  patientIdZipCode: '85001',
  patientIdBirthPlace: 'austin',
  patientIdGeoCode: '123456',
  patientIdWHOId: '70A809', // Need to update
  patientIdMotherMaidName: 'Jones',
  patientIdUniqAlphaNum: 'H', // Need to update
  success: 'success',
  fail: 'fail',
  breachParamTidalVolume: 'TV',
  breachParamRespirationRate: 'RR',
  breachParamS3Energy: 'S3',
  breachParamThoracicImpedance: 'TI',
  breachParamHeartRate: 'HR',
  breachParamQTC: 'QTC',
  breachParamQRS: 'QRS',
  patientReadingComp: 'COMPLIANT',
  patientReadingSomeWhatComp: 'SOME_WHAT_COMPLIANT',
  patientReadingNotVeryComp: 'NOT_VERY_COMPLIANT',
  patientReadingNotComp: 'NOT_COMPLIANT',
  patientPartialReport: 'PARTIAL',
  patientNotReceivedReport: 'NOT_RECEIVED',
  patientFutureReport: 'FUTURE',
  patientReceivedReport: 'RECEIVED',
  patientReadingCrntWK: 'last 7 days',
  patientReadingLastWK: '14 days ago',
  patientReadingLast7Days: 'last 7 days',
  patientReadingLast14Days: '14 days ago',
  patientReadingLast21Days: '21 days ago',
  patientReadingLast28Days: '28 days ago',
  patientReadingLast30Days: '30 days ago',
  insufficientData: 'Insufficient_Data',
  notApplicable: 'NA',
  breachParameters: [
    { label: 'supineTidalVol', key: 'breachParamTidalVolume' },
    { label: 'supineRespirationRate', key: 'breachParamRespirationRate' },
    { label: 'fowlerTidalVol', key: 'breachParamTidalVolume' },
    { label: 'fowlerRespirationRate', key: 'breachParamRespirationRate' },
    { label: 's3Energy', key: 'breachParamS3Energy' },
    { label: 'thoracicImpedance', key: 'breachParamThoracicImpedance' },
    { label: 'heartRate', key: 'breachParamHeartRate' },
    { label: 'qtcWidth', key: 'breachParamQTC' },
    { label: 'qrsWidth', key: 'breachParamQRS' },
    { label: 'angle1', key: '' },
    { label: 'angle2', key: '' },
    { label: 'fowlerRRTv', key: '' },
    { label: 'supineRRTv', key: '' },
    { label: 'deltaZ', key: '' }
  ],
  thresholdRangeType: 'RANGE',
  thresholdMinType: 'MIN',
  thresholdMaxType: 'MAX'
} as IConfig);
