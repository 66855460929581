import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'version' })
export class VersionPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      return value < 10 ? `0${value}` : value;
    }
  }
}
