<header class="header mb-4">
  <div class="container-fluid">
    <div class="main-nav row  justify-content-between">
      <div class="col-auto">
        <img alt="Logo" class="logo" src="/assets/cpm-logo-whitemdpi.png">
      </div>
      <div *ngIf="isLoggedIn" class="col-auto logged-in">
        <div class="d-none d-inline-block">
          <p class="m-0">Logged in as
            <span class="email-log"> {{email}}</span>
          </p>
        </div>
        <div class="logout-btn d-inline-block">
          <a (click)="signOut()" aria-label="Exit" href="javascript:void(0)">
            <span class="logout-helper-text mr-2 ml-2">Logout</span>
            <mat-icon color="primary" style="position:relative;top:6px">exit_to_app</mat-icon>
          </a>
        </div>
      </div>

    </div>
  </div>
</header>
<div class="container">
  <div class="row mb-5 justify-content-center">
    <div class="col-md-6">
      <p>
        <strong translate>
          You don't have the required permissions to access patient records.
          Please contact your administrator.</strong>
      </p>
      <hr class="mt-15">
      <div class="row mb-3" [ngClass]="isLoggedIn ?  'justify-content-between' : 'justify-content-center'">
        <div class="col-auto" *ngIf="isLoggedIn">
          <button (click)="openHomePage()" color="primary" mat-flat-button translate type="button">Start Over</button>
        </div>
        <div class="col-auto">
          <button (click)="signOut()" color="primary" mat-flat-button translate type="button">{{isLoggedIn ? 'Log Out' :
            'Go back to Login'}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
