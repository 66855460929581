import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dateFix' })
export class DateFixPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      return moment(value);
    }
  }
}
