import { Multi } from '../language/multi-en';

export const ErrorMsg =
{
    500: Multi.INTERNAL_SERVER_ERROR,
    400: Multi.INTERNAL_SERVER_ERROR,
    0: Multi.NETWORK_ERROR,
    403: Multi.UNAUTHRIZED_OPERATION,
    401: Multi.UNAUTHENTICATED_USER,
    /** Global error codes messages */

    UPDATE_IS_TNC_ACCEPTED_500: Multi.TERMS_ERR_MSG,
    UPDATE_IS_TNC_ACCEPTED_400: Multi.TERMS_ERR_MSG
};
